import React from "react";

import Message from "./messages/Message";
import MessagesButton from "./messages/MessagesButton";
import Display from "./displays/Display";
import SectionHeading from "./section/SectionHeading";
import Section from "./section/Section";

import isMessageActive from "../utils/isMessageActive";
import { useGetDisplays } from "../services/api/displays";
import { useGetMessages } from "../services/api/messages";

import "./styles/dashboard.css";

export default function UserDashboard() {
	const displaysListQueryParams = {
    _sort: 'stop_name:ASC',
  };
	const { data: displaysData } = useGetDisplays({ params: displaysListQueryParams });
	const { data: messagesData } = useGetMessages("/messages");

	const displays = displaysData?.map(display => (
		<Display
			key={display.id}
			display={display}
		/>
	));

	const messages = messagesData?.filter(isMessageActive).map(message => (
		<Message
			key={message.id}
			message={message}
		/>
	));

	return (
		<div className="user-dashboard">
			<Section
				sectionName="messages"
				content={messages}
			>
				<SectionHeading
					sectionName="messages"
					headingText="messages.validMessages"
				>
					<MessagesButton
						linkTo="/messages/new"
						buttonText="messages.addNew"
					/>
				</SectionHeading>
			</Section>
			<Section
				sectionName="displays"
				content={displays}
			>
				<SectionHeading
					sectionName="displays"
					headingText="displays.stopsDisplays"
				/>
			</Section>
		</div>
	);
}