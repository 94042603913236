import React from "react";

import "../styles/section-content.css";

export default function SectionContent(props) {
  return (
    <div className={`section-content ${props.sectionName}`}>
      {props.content}
    </div>
  );
}