import React from "react";
import SectionContent from "./SectionContent";

import "../styles/section.css";

export default function Section(props) {
  return (
    <section className={`section ${props.sectionName}`}>
      {props.children}
      <SectionContent sectionName={props.sectionName} content={props.content} />
    </section>
  );
}