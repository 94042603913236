import React, { useCallback, useContext, useMemo, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { AppContext } from "../context/context";
import { useResetPassword } from "../services/api/auth";
import getStrapiError from "../utils/getStrapiError";

import "./styles/form.css";
import { useShowPassword } from "../hooks/useShowPassword";
import { ShowPassword } from "./ShowPassword";

const initialState = {
	password: "",
	newPassword: "",
	newPasswordConfirm: "",
};

export default function PasswordChange() {
	const { user } = useContext(AppContext);
	const [form, setForm] = useState(initialState);
	const [clientError, setClientError] = useState(false);
	const { showPassword, toggleShowPassword } = useShowPassword();
	const intl = useIntl();

	const { mutate: changePassword, isLoading, isError, error, isSuccess } = useResetPassword();
	const handleChangePassword = useCallback(() => {
		changePassword({ userId: user.id, data: form })
	}, [user.id, changePassword, form]);
	const strapiError = useMemo(() => getStrapiError(error?.data), [error]);

	const handleInputChange = useCallback(({target}) => {
		const { name, value } = target;
		setForm(form => ({
			...form,
			[name]: value
		}));

		if (clientError) {
			setClientError(false);
		}
	}, [clientError]);

	const handleSubmit = useCallback(event => {
		event.preventDefault();
		
		if (form.newPassword !== form.newPasswordConfirm) {
			setClientError("Auth.form.error.match");
			return;
		}

		if (form.newPassword.length === 0 || form.newPasswordConfirm.length === 0) {
			setClientError("Auth.form.error.shortPassword");
			return;
		}

		handleChangePassword(form);
	}, [handleChangePassword, form]);

	if (isSuccess) {
		return <Redirect to="/" />;
	}

	return (
		<div className="form password-change-form">
			<h3><FormattedMessage id="password.changePassword" /></h3>
			<form>
				<input
					className="form-input password-change-input"
					type={showPassword ? "text" : "password" }
					placeholder={intl.formatMessage({id: "password.oldPassword"})}
					value={form.password}
					name="password"
					autoComplete="off"
					onChange={handleInputChange}
				/>
				<input
					className="form-input password-change-input"
					type={showPassword ? "text" : "password" }
					placeholder={intl.formatMessage({id: "password.newPassword"})}
					value={form.newPassword}
					name="newPassword"
					autoComplete="off"
					onChange={handleInputChange}
				/>
				<input
					className="form-input password-change-input"
					type={showPassword ? "text" : "password" }
					placeholder={intl.formatMessage({id: "password.newPasswordConfirm"})}
					value={form.newPasswordConfirm}
					name="newPasswordConfirm"
					autoComplete="off"
					onChange={handleInputChange}
				/>
				<ShowPassword toggleShowPassword={toggleShowPassword} />
				{(clientError || isError) &&
					<p className={`password-validation-error ${(isError || clientError) ? "active" : "inactive"}`}>
						<FormattedMessage id={`password.changeError.${clientError || strapiError?.errorId}`} />
					</p>
				}
				<div className="form-buttons">
					<button
						className="form-button submit"
						onClick={handleSubmit}
					>
						{
							isLoading
							?
							<FormattedMessage id="global.wait" />
							:
							<FormattedMessage id="password.change" />
						}
					</button>
					<Link to="/user-dashboard" className="form-button cancel">
						<FormattedMessage id="global.cancel" />
					</Link>
				</div>
			</form>
		</div>
	);
}