import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import * as serviceWorker from './serviceWorker';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Root from './components/Root';

import { ContextProvider } from "./context/context";
import intlMessages from "./resources/intlMessages";
import flattenMessages from "./utils/flattenMessages";
import "./index.css";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	}
});

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<ContextProvider>
			<IntlProvider locale="lt" messages={flattenMessages(intlMessages)}>
				<BrowserRouter>
					<Route component={Root} />
				</BrowserRouter>
			</IntlProvider>
		</ContextProvider>
	</QueryClientProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
