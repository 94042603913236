import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Tooltip from "../Tooltip";
import SettingsIcon from "../icons/Settings";
import Icon from "../icons/Icon";

import isMessageActive from "../../utils/isMessageActive";
import { useDeleteMessage } from "../../services/api/messages";

import "../styles/item.css";
import "../styles/message.css";

export default function Message(props) {
	const { message } = props;
	const [settingsActive, setSettingsActive] = useState(false);
	const { mutate: deleteMessage } = useDeleteMessage();
	const messageIsActive = isMessageActive(message);

	const handleDeleteMessage = useCallback(() => deleteMessage(message.id), [deleteMessage, message.id]);

	function toggleSettings(event) {
		if (event.type === "mouseleave" && !settingsActive) {
			return;
		}
		setSettingsActive(active => !active);
	}

	function confirmDeletion() {
		const confirmation = window.confirm("Ar tikrai norite ištrinti pranešimą?");
		if (confirmation) {
			handleDeleteMessage();
		}
	}

	return (
		<div
			className={`item message ${messageIsActive ? "online" : "offline"}`}
			onMouseLeave={toggleSettings}
		>
			<div className="message-summary">
				<h3>{ message.message }</h3>
			</div>
			<div style={{marginLeft: "auto", marginRight: "1rem"}}>
				<FormattedMessage
					id="displays.assignedDisplays"
					description="Number of displays a message is assigned to"
					defaultMessage="Pranesimo galiojimo laikas"
					values={{
						assignedDisplays: message.displays.length,
					}}
				/>
			</div>
			<Tooltip
				handleClick={toggleSettings}
				content="Daugiau veiksmų"
				direction="left"
			>
					<Icon
						icon={SettingsIcon}
						iconProps={{
							size: 20,
							fill: "#000",
						}}
					/>
			</Tooltip>
			<div className={`actions-menu ${settingsActive ? "opened" : "closed"}`}>
				<Link className="message-action" to={`/messages/edit/${message.id}`}>
					<FormattedMessage id="messages.editMessage" />
				</Link>
				<p onClick={confirmDeletion} className="message-action delete">
					<FormattedMessage id="messages.deleteMessage" />
				</p>
			</div>
		</div>
	);
}