import React, { useState } from "react";

import "./styles/tooltip.css";

export default function Tooltip(props) {
	let timeout;
	const [active, setActive] = useState(false);

	function showTip() {
		timeout = setTimeout(() => {
			setActive(true);
		}, 400);
	}

	function hideTip() {
		clearInterval(timeout);
		setActive(false);
	}

	return (
		<div
			className="tooltip-wrapper"
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
			onClick={props.handleClick}
			style={props.style}
		>
			{props.children}
			{active && (
				<div className={`tooltip-tip ${props.direction || "top"}`}>
					{props.content}
				</div>
			)}
		</div>
	);
}