import React from "react";

import Section from "../section/Section";
import SectionHeading from "../section/SectionHeading";
import Message from "./Message";
import MessagesButton from "./MessagesButton";

import { useGetMessages } from "../../services/api/messages";

export default function MessagesList() {
  const messagesListRequestParams = {
    _sort: "valid_to:DESC",
  };
  const { data: messagesList } = useGetMessages("/messages", { params: messagesListRequestParams });

  const messages = messagesList?.map(message => (
		<Message
			key={message.id}
			message={message}
		/>
	));

  return (
    <Section sectionName="messages-list" content={messages}>
      <SectionHeading
        sectionName="messages-list"
        headingText="messages.allMessages"
      >
        <MessagesButton
          linkTo="/messages/new"
          buttonText="messages.addNew"
        />
      </SectionHeading>
    </Section>
  );
}