import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";

import { AppContext } from "../../context/context";
import { useApi } from "../../hooks/useApi";

export function useLogin() {
	const { handleLoginSuccess } = useContext(AppContext);
	const { post } = useApi();

	return useMutation(
		data => post('/auth/local', { data }),
		{
			onSuccess: payload => handleLoginSuccess(payload),
		}
	);
}

export function useLogout() {
	const { handleLogout } = useContext(AppContext);
	handleLogout();
}

export function useResetPassword() {
	const { put } = useApi();

	return useMutation(
		({userId, data}) => put(`/users-permissions/users/${userId}/password-change`, { data }),
		{
			onSuccess: () => window.location.reload,
		}
	);
}