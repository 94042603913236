import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { useApi } from "../../hooks/useApi";

export function useGetMessages(url, params = {}) {
	const { get } = useApi();
	const paramsValues = Object.entries(params.params || {});
	const queryKey = paramsValues.join(",");
	return useQuery(
		[url, queryKey],
		() => get(url, params),
		{ enabled: !!url }
	);
}

export function useDeleteMessage() {
	const queryClient = useQueryClient();
	const { destroy } = useApi();

	return useMutation(
		messageId => destroy(`/messages/${messageId}`),
		{
			onSuccess: () => queryClient.invalidateQueries(["/messages"]),
		}
	);
}

export function useCreateOrUpdateMessage() {
	const queryClient = useQueryClient();
	const { put, post } = useApi();

	return useMutation(
		({messageId, data}) => {
			if (messageId) {
				const url = `/messages/edit/${messageId}`;
				return put(url, { data });
			} else {
				return post("/messages/new", { data })
			}
		},
		{
			onSuccess: () => queryClient.invalidateQueries(["/messages"]),
		}
	);
}