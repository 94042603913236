import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { ShowPassword } from "../ShowPassword";

import "../styles/login-form.css";

function LoginForm(props) {
	const intl = useIntl();

	return (
		<div className="login-window">
			<h2>
				<FormattedMessage id="login.login" />
			</h2>
			{
				props.error
				&&
				<div className="login-error-message">
					<FormattedMessage id={`login.errors.${props.error}`} />
				</div>
			}
			<form className="login-form">
				<input
					className="login-input"
					type="text"
					name="username"
					placeholder={intl.formatMessage({id: 'login.username'})}
					value={props.form.username}
					autoComplete="off"
					onChange={props.handleChange}
				/>
				<input
					className="login-input"
					type={props.showPassword ? "text" : "password"}
					name="password"
					placeholder={intl.formatMessage({id: 'login.password'})}
					value={props.form.password}
					autoComplete="off"
					onChange={props.handleChange}
				/>
				<ShowPassword toggleShowPassword={props.toggleShowPassword}/>
				<button
					className="login-button"
					onClick={props.handleLogin}
				>
					{
						props.isLoading
						?
						<FormattedMessage id="login.loggingIn" />
						:
						<FormattedMessage id="login.login" />
					}
				</button>
			</form>
		</div>
	);
}

export default LoginForm;