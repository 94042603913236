import { useQuery } from "@tanstack/react-query";

import { useApi } from "../../hooks/useApi";

export function useGetDisplays(params) {
	const { get } = useApi();
	return useQuery(["/displays"], () => get("/displays", params));
}

export function useGetDisplayStatus(stopId) {
	const { get } = useApi();
	const url = `/displays/${stopId}/status`;
	return useQuery(
		[url],
		() => get(url),
		{
			cacheTime: 20 * 1000,
			refetchInterval: 20 * 1000,
			staleTime: 20 * 1000,
			enabled: !!stopId,
		}
	);
}