import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { format, isSameDay, endOfDay, startOfDay, subDays, compareDesc } from "date-fns";
import { lt } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import { SwatchesPicker } from 'react-color';

import useMessageForm from "../../hooks/useMessageForm";
import { useGetDisplays } from "../../services/api/displays";
import { useGetMessages, useCreateOrUpdateMessage } from "../../services/api/messages";
import getStrapiError from "../../utils/getStrapiError";

import 'react-nice-dates/build/style.css'
import "../styles/datepicker.css";
import "../styles/form.css";

function MessageForm() {
	const params = useParams();
	const history = useHistory();
	const { data: displays } = useGetDisplays();
	const  {data: messages } = useGetMessages("/messages");
	const { mutate: updateMessage, error, isLoading, isSuccess } = useCreateOrUpdateMessage();
	const strapiError = useMemo(() => getStrapiError(error?.data), [error]);

	const handleUpdateMessage = useCallback(data => {
		updateMessage({messageId: params.id, data});
	}, [updateMessage, params.id]);
	
	const { form, setForm, validFrom, validTo, setValidFrom, setValidTo } = useMessageForm(displays, messages, params);
	const [symbolsEntered, setSymbolsEntered] = useState(0);
	const [charactersLimit, setCharactersLimit] = useState(0);
	const maxRecommendedSymbols = 150;

	useEffect(() => {
		if (isSuccess) {
			history.goBack();
		}
	}, [isSuccess, history]);
	
	useEffect(() => {
		setSymbolsEntered(form.message.length);
	}, [form]);
	
	const intl = useIntl();

	function handleInputChange({target}) {
		const { name, type, value } = target;

		if (type === "checkbox") {
			const newData = {};
			const displayId = parseInt(value, 10);

			if (target.checked) {
				if (name === "allDisplays") {
					newData.displays = displays?.map(i => i.id);
					newData.allDisplays = true;
				} else {
					newData.displays = [...form.displays, displayId];
					if (displays?.every(i => newData.displays.includes(i.id))) {
						newData.allDisplays = true;
					} else {
						newData.allDisplays = false;
					}
				}
			} else {
				if (name === "allDisplays") {
					newData.displays = [];
				} else {
					newData.displays = form.displays.filter(d => d !== displayId);
				}
				newData.allDisplays = false;
			}

			setForm(form => ({
				...form,
				...newData,
			}));
		} else {
			setForm(form => ({
				...form,
				[name]: value
			}));
			setSymbolsEntered(value.length);
			if (value.length > maxRecommendedSymbols) {
				setCharactersLimit(true);
			} else {
				setCharactersLimit(false);
			}
		}
	}

	function handleColorChange(color) {
		setForm(form => ({
			...form,
			color: color.hex
		}));
	}

	function handleSubmit(event) {
		event.preventDefault();
		const datetimeFormat = "yyyy-MM-dd HH:mm:ss";
		form.valid_from = format(startOfDay(validFrom), datetimeFormat);

		if (isSameDay(validTo, validFrom)) {
			form.valid_to = format(endOfDay(validTo), datetimeFormat);
		} else if (compareDesc(validFrom, validTo) === -1) {
			form.valid_to = format(endOfDay(validFrom), datetimeFormat);
		} else {
			form.valid_to = format(endOfDay(subDays(validTo, 1)), datetimeFormat);
		}

		handleUpdateMessage(form);
	}

	return (
		<div className="form message-form">
			<h3><FormattedMessage id={params.id ? "messages.editMessage" : "messages.createNewMessage"} /></h3>
			<form>
				<div className="message-form-message">
					<textarea
						className={`form-input message-form-input ${charactersLimit ? "characters-limit-warning" : "characters-limit-ok"}`}
						rows={4}
						name="message"
						placeholder={intl.formatMessage({id: 'messages.message'})}
						value={form.message}
						autoComplete="off"
						onChange={handleInputChange}
					/>
					<span className="message-length">{symbolsEntered}/{maxRecommendedSymbols}</span>
				</div>
				<div className="message-form-color" style={{marginBottom: '10px'}}>
					<label><p><FormattedMessage id="messages.color" />: {form.color}</p></label>
					<SwatchesPicker
						width='100%'
						height='160'
						color={form.color}
						onChangeComplete={handleColorChange}
					/>
				</div>
				<DatePicker date={validFrom} onDateChange={setValidFrom} locale={lt} format="yyyy-MM-dd">
					{({ inputProps, focused }) => (
						<label>
							<p><FormattedMessage id="messages.validFrom" /></p>
							<input className={`input ${focused ? ' -focused' : ''} form-input message-form-input`} style={{ width: 150 }} {...inputProps} />
						</label>
					)}
				</DatePicker>
				<DatePicker minimumDate={validFrom} date={validTo} onDateChange={setValidTo} locale={lt} format="yyyy-MM-dd">
					{({ inputProps, focused }) => (
						<label>
							<p><FormattedMessage id="messages.validTo" /></p>
							<input className={`input ${focused ? ' -focused' : ''} form-input message-form-input`} style={{ width: 150 }} {...inputProps} />
						</label>
					)}
				</DatePicker>
				<FormattedMessage id="messages.chooseStops" />
				<ul className="stops-list">
					<li className="stops-list-item">
						<label>
							<input type="checkbox" checked={form.allDisplays} name="allDisplays" value={form.allDisplays} onChange={handleInputChange}/>
							<span className="stops-list-item-name all">
								<FormattedMessage id="messages.assignToAll" />
							</span>
						</label>
					</li>
					{
						displays?.map(d =>
							<li key={d.id} className="stops-list-item">
								<label>
									<input type="checkbox" checked={form.displays.includes(d.id)} name={d.stop_name} value={d.id} onChange={handleInputChange}/>
									<span className="stops-list-item-name">{d.stop_name}</span>
								</label>
							</li>
						)
					}
				</ul>
				{
					error &&
					<div className="form-error">
						<FormattedMessage id={strapiError?.errorId || error?.message} />
					</div>
				}
				<div className="form-buttons">
					<button
						className="form-button submit"
						onClick={handleSubmit}
					>
						{
							isLoading
							?
							<FormattedMessage id="global.wait" />
							:
							<FormattedMessage id={params.id ? "global.save" : "global.add"} />
						}
					</button>
					<Link to="/user-dashboard" className="form-button cancel">
						<FormattedMessage id="global.cancel" />
					</Link>
				</div>
			</form>
		</div>
	)
}

export default MessageForm;