import axios from "axios";
import jwtDecode from "jwt-decode";
import React, { useMemo, createContext, useState, useCallback } from "react";
import config from "../resources/config";
export const AppContext = createContext();

const localStorageUserKey = "user";

export function ContextProvider(props) {
	const [user, setUser] = useState(null);
	const client = useMemo(() => axios.create({ baseURL: config.api.root }), []);;

	const setTokenHeader = useCallback(token => {
		client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}, [client.defaults.headers.common]);

	const handleLoginSuccess = useCallback(payload => {
		const { jwt, user: userData } = payload;
		const newUser = {
			loggedIn: true,
			loggedInAt: new Date(),
			...Object.assign(jwtDecode(jwt), {...userData, token: jwt}),
		};
		localStorage.setItem(localStorageUserKey, JSON.stringify(newUser));
		setUser(newUser);
		setTokenHeader(jwt);
	}, [setTokenHeader]);

	const handleLogout = useCallback(() => {
		localStorage.removeItem(localStorageUserKey);
		delete client.defaults.headers.common['Authorization'];
		setUser(null);
	}, [client.defaults.headers.common]);
	
	const checkForUser = useCallback(() => {
		if (!user) {
			const localStorageUser = localStorage.getItem(localStorageUserKey);

			if (localStorageUser !== null) {
				const userData = JSON.parse(localStorageUser);
				const now = Math.floor(new Date().getTime() / 1000);
				const userExpired = now > userData.exp;

				if (userExpired) {
					const user = {
						loggedIn: false,
						loading: false,
						error: "TokenExpired",
					};
					return user;
				}
				
				setTokenHeader(userData.token);
				setUser(userData);
			}
		}
	}, [user, setTokenHeader]);

	return (
		<AppContext.Provider value={{user, client, handleLoginSuccess, handleLogout, checkForUser}}>
			{ props.children }
		</AppContext.Provider>
	)
}