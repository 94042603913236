import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import "../styles/messages-button.css";

export default function MessagesButton(props) {
  return (
    <Link to={props.linkTo} className="messages-button">
      <FormattedMessage id={props.buttonText} />
    </Link>
  )
}