import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import App from './App';
import LoginContainer from './login/Login';

import { AppContext } from "../context/context";

export default function Root() {
	const { user, checkForUser } = useContext(AppContext);
	
	useEffect(() => checkForUser(), [checkForUser]);
	
	let component;

	if (!user) {
		component = <Redirect to="/login" />;
	} else {
		component = <Route path="/" component={App} />
	}

	return (
		<>
			{ component }
			<Route path="/login" component={LoginContainer}/>
		</>
	);
};