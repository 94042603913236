import { isAfter, isBefore, parseISO } from "date-fns";

export default function isMessageActive(message) {
  if (!message) {
    return false;
  }

  const now = new Date();
  const validFrom = parseISO(message.valid_from);
  const validTo = parseISO(message.valid_to);
  return isAfter(now, validFrom) && isBefore(now, validTo);
}