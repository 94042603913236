import React, { useContext } from "react";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { AppContext } from "../context/context";

import UserDashboard from "./UserDashboard";
import MessageForm from "./messages/MessageForm";
import MessagesList from "./messages/MessagesList";
import PasswordChange from "./PasswordChange";

import "./styles/app.css"

export default function App() {
	const { user, handleLogout } = useContext(AppContext);

	return (
		<div className="app">
			<header className="app-header">
				<nav className="app-navigation">
					<NavLink className="nav-link" to="/user-dashboard">
						<FormattedMessage id="displays.displays" />
					</NavLink>
					<NavLink className="nav-link" to="/messages/all">
						<FormattedMessage id="messages.allMessages" />
					</NavLink>
				</nav>
				<div className="user-menu">
					<span>{ user.username }</span>
					<NavLink to="/password-change" className="nav-link">
						<FormattedMessage id="password.changePassword"/>
					</NavLink>
					<div>
						<button onClick={handleLogout}>
							<FormattedMessage id="global.logout" />
						</button>
					</div>
				</div>
			</header>
			<main className="main">
				<Switch>
					<Route exact path="/" render={() => <Redirect to="/user-dashboard" />} />
					<Route path="/user-dashboard" component={UserDashboard} />
					<Route path="/messages/all" component={MessagesList} />
					<Route path="/messages/new" render={() => <MessageForm path="/messages/new" formType="new" />} />
					<Route path="/messages/edit/:id" render={() => <MessageForm path="/messages/edit/:id" />} />
					<Route path="/password-change" component={PasswordChange} />
				</Switch>
			</main>
		</div>
	);
}
