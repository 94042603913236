/* EXAMPLE:
	{
		"statusCode": 400,
		"error": "Bad Request",
		"message": [
			{
				"messages": [
					{
						"id": "Auth.form.error.invalid",
						"message": "Identifier or password invalid."
					}
				]
			}
		],
		"data": [
			{
				"messages": [
					{
						"id": "Auth.form.error.invalid",
						"message": "Identifier or password invalid."
					}
				]
			}
		]
	}
*/

function getMessageId(item) {
	if (Array.isArray(item)) {
		for (let i of item) {
			if (Object.keys(i).includes("id")) {
				return i.id;
			} else {
				return getMessageId(i)
			}
		}
	} else {
		const keys = Object.keys(item);
		const requiredValue = item?.message || item?.data || item?.messages;
		if (requiredValue) {
			return getMessageId(requiredValue);
		}	else if (keys.includes("id")) {
			return item.id;
		} else {
			return null;
		}
	}
}

export default function getStrapiError(errorObj) {
	if (!errorObj) {
		return null;
	}

	const { statusCode, error } = errorObj.data;
	const errorId = getMessageId(errorObj);
	return { statusCode, error, errorId };
}

