import { lt } from "date-fns/locale";
import { parseISO, format } from "date-fns";

function padZero(value) {
  return value < 10 ? `0${value}` : value;
}

export function secondsToTime(seconds, join = false) {
  const hh = padZero(Math.floor(seconds / 3600));
  const mm = padZero(Math.floor(((seconds % 3600) / 60)));
  const sec = padZero(seconds % 60);

  if (join) {
    return `${hh}:${mm}:${sec}`;
  }

  return Object.assign({}, hh > 0 && { hh }, mm > 0 && { mm }, { sec });
}

export function utcToLocal(date = new Date(), format) {
  return format(date, 'yyyy-MM-dd HH:mm:ss', { locale: lt })
}

export function formatDisplayTime(time) {
	const parsedTime = parseISO(time);
	const diff = getRemainingTime(parsedTime);
	
	if (diff < 60) { // less than 1 minute
		return "0 min";
	} else if (diff < 20 * 60) { // less than 20 minutes
		const arrivesInMinutes = Math.floor((diff % 3600) / 60);
		return `${arrivesInMinutes} min`;
	} else {
		return format(parsedTime, "HH:mm"); // over 20 minutes
	}
}

function getRemainingTime(time) {
	return Math.floor(time.getTime() / 1000) - Math.floor(new Date().getTime() / 1000);
}