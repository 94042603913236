import React from "react";
import { format, differenceInSeconds, parseISO, isBefore, startOfToday } from "date-fns";
import { FormattedMessage } from "react-intl";

import { useGetDisplayStatus } from "../../services/api/displays";
import isMessageActive from "../../utils/isMessageActive";
import { formatDisplayTime } from "../../utils/datetime";

import "../styles/item.css";
import "../styles/display.css";

export default function Display(props) {
	const { display } = props;
	const hasActiveMessages = display.messages.some(isMessageActive);
	const { data, isError } = useGetDisplayStatus(display.stop_id);
	let normalisedStatus;
	const updatedAtInSeconds = differenceInSeconds(new Date(), parseISO(data?.updatedAt));

	if (data?.status === "offline") {
		normalisedStatus = data?.status;
	} else {
		if (updatedAtInSeconds > 3 * 60) { // 3 minutes
			normalisedStatus = "offline";
		} else {
			normalisedStatus = data?.status;
		}
	}

	const stopName = display.stop_name;
	const updatedAtDatetime = data?.updatedAt ? parseISO(data.updatedAt) : null;
	const updatedBeforeToday = isBefore(updatedAtDatetime, startOfToday());
	const displayUpdatedAt = data?.updatedAt ? format(updatedAtDatetime, updatedBeforeToday ? "yyyy-MM-dd HH:mm:ss" : "HH:mm:ss") : "";

	return (
		<div className={`item display ${normalisedStatus === "online" ? "online" : "offline" }`}>
			<div className="display-stop-header">
				<span className="stop-name">
					{stopName}
				</span>
				<span className="display-updated-at">
					{displayUpdatedAt}
				</span>
			</div>
			<div className="display-data">
				{
					isError
					?
						<FormattedMessage id="displays.dataUnavailable" />
					:
					Object.keys(data?.rows || {}).length && normalisedStatus === "online"
					?
						<StopDepartures rows={data?.rows} hasActiveMessages={hasActiveMessages} />
					:
						<FormattedMessage id="displays.noData" />
				}
			</div>
		</div>
	);
}

function StopDepartures(props) {
	const rowData = Object.entries(props.rows || {}).sort((a, b) => a?.[0] - b?.[0]);

	const departures = rowData?.map(([_, trip], ind) => {
			const {route, destination, time} = trip;
			return (
				<li key={ind} className="display-data-item">
					<span className="route">{route.toUpperCase()}</span>
					<span className="destination">{destination}</span>
					<span className="time">{formatDisplayTime(time)}</span>
				</li>
			)
		}
	);

	return (
		<ol className="display-data-items">
			{departures}
		</ol>
	);
}
