import React from "react";
import { FormattedMessage } from "react-intl";

import "./styles/show-password.css";

export function ShowPassword(props) {
  return (
    <div className="show-password">
      <label>
        <input
          type="checkbox"
          onChange={props.toggleShowPassword}
        />
        <FormattedMessage id="login.showPassword" />
      </label>
    </div>
  );
}