import React, { useState, useContext, useCallback, useMemo } from "react";
import { Redirect } from "react-router-dom";

import LoginForm from "./LoginForm";
import { AppContext } from "../../context/context";
import { useLogin } from "../../services/api/auth";
import getStrapiError from "../../utils/getStrapiError";
import { useShowPassword } from "../../hooks/useShowPassword";

const initialState = {
	username: "",
	password: "",
};

function Login() {
	const [form, setForm] = useState(initialState);
	const { showPassword, toggleShowPassword } = useShowPassword();
	const { user } = useContext(AppContext);
	const { mutate: login, error, isLoading } = useLogin();
	const strapiError = useMemo(() => getStrapiError(error?.data), [error]);

	const handleLogin = useCallback(event => {
		event.preventDefault();
		login({identifier: form.username, password: form.password });
	}, [login, form.username, form.password]);

	function handleInputChange(event) {
		const { value, name } = event.target;
		setForm(form => ({
			...form,
			[name]: value
		}));
	}

	if (user) {
		return <Redirect to="/" />;
	}

	return (
		<LoginForm
			form={form}
			error={user?.error || strapiError?.errorId || error?.message}
			user={user}
			showPassword={showPassword}
			toggleShowPassword={toggleShowPassword}
			isLoading={isLoading}
			handleChange={handleInputChange}
			handleLogin={handleLogin}
		/>
	);
};

export default Login;