import { useCallback, useContext } from "react";

import { AppContext } from "../context/context";
import getStrapiError from "../utils/getStrapiError";

export function useApi() {
	const { client, handleLogout } = useContext(AppContext);

	const call = useCallback(async (url, method, options) => {
		const config = {
			url,
			method,
		};
		const params = Object.assign(config, options);

		try {
			const response = await client(params);

			if (response.status === 401) {
				handleLogout();

				if (response.data.code) {
					throw new Error(response.data.code);
				}

				if (response.data.message) {
					const err = getStrapiError(response.data);
					throw new Error(err);
				}
			}

			const result = response.data;
			return result;
		} catch (error) {
			if (error.response) {
				if (error.response.status === 401) {
					handleLogout();
				}

				const err = new Error('Failed server request');
				err.message = error.response.statusText;
				err.status = error.response.status;
				err.data = error.response.data;
				throw err;
			}
	
			if (error.request) {
				throw new Error('Request error');
			}
	
			throw error;
		}
	}, [client, handleLogout]);

	const get = useCallback(async (url, params) => call(url, 'get', params), [call]);

	const post = useCallback((url, params) => call(url, 'post', params), [call]);

	const put = useCallback((url, params) => call(url, 'put', params), [call]);

	const destroy = useCallback(async (url) => call(url, 'delete'), [call]);

	return { get, put, post, destroy };
}