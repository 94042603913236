import React from "react";

import "../styles/icon-wrapper.css";

export default function Icon(props) {
  const { icon: IconComponent, iconProps } = props;

  return (
    <div className="icon-wrapper">
      {props.children}
      <IconComponent {...iconProps} />
    </div>
  );
}