import React from "react";
import { FormattedMessage } from "react-intl";

import "../styles/section-heading.css";

export default function SectionHeading(props) {
  return (
    <div className={`section-heading ${props.sectionName}`}>
      <h3>
        <FormattedMessage id={props.headingText} />
      </h3>
      {props.children}
    </div>
  );
}